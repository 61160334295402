<template>
  <b-row
    v-bind="$attrs"
  >
    <slot />
  </b-row>
</template>

<script>
export default {
  name: 'SRow',
}
</script>

<style scoped>

</style>
