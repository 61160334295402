const state = {
  user: null,
  menuItems: [],
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_AUTH_USER(state, payload) {
    state.user = payload
  },
  SET_MENU_MODULES(state, payload) {
    state.menuItems = payload
  },
}

const actions = {
  me: async ({ commit }) => {
    try {
      const data = await window.http.get('auth/me')
      localStorage.setItem('userData', JSON.stringify(data))
      commit('SET_AUTH_USER', data)
      return data
    } catch (error) {
      return error
    }
  },
  setUser: async ({ commit }, user) => {
    commit('SET_AUTH_USER', user)
  },
  setMenuModules({ commit }, items) {
    commit('SET_MENU_MODULES', items)
  },
  getMenusFromModule: async ({ commit }, moduleId) => {
    const menuItems = await window.http.get(`auth/me/modules/${moduleId}/permissions`)
    commit('SET_MENU_MODULES', menuItems)
  },
  clearMenuModules: async ({ commit }) => {
    // commit('SET_MENU_MODULES', [])
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
