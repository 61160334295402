const dynamicField = [
  {
    path: '/dynamic-fields',
    name: 'dynamic-fields.list',
    meta: {
      pageTitle: 'Campos Dinâmicos',
      breadcrumb: [
        {
          text: 'Campos Dinâmicos',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dynamicfield/DynamicFieldList.vue'),
  },
]

export default dynamicField
