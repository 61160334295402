const types = [
  {
    path: '/types',
    name: 'type.list',
    component: () => import('@/views/types/TypeList.vue'),
  },
  {
    path: '/types/stop',
    name: 'type.stop.list',
    component: () => import('@/views/types/TypeList.vue'),
  },
  {
    path: '/types/maintenance',
    name: 'type.maintenance.list',
    component: () => import('@/views/types/TypeList.vue'),
  },
]

export default types
