const profile = [
  {
    path: '/menus',
    name: 'menu.list',
    component: () => import('@/views/permission/MenuList.vue'),
    meta: {
      pageTitle: 'Menus',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },

  {
    path: '/menus/create',
    name: 'menu.create',
    component: () => import('@/views/permission/MenuBuilder.vue'),
  },
  {
    path: '/menus/:uuid/edit',
    name: 'menu.edit',
    component: () => import('@/views/permission/MenuBuilder.vue'),
  },
]

export default profile
