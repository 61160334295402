const profiles = [
  {
    path: '/profiles',
    name: 'profiles.list',
    component: () => import('@/views/profiles/ProfilesList.vue'),
    meta: {
      pageTitle: 'Perfis',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
]

export default profiles
