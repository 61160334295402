import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loader from './loader'
import app from './app'
import appConfig from './app-config'
import typeIndex from './types/type_index'
import machineCategories from './types/machine-categories'
import stopCategories from './types/stop-categories'
import categories from './types/categories'
import machines from './machine/machines'
import workstations from './workstation/workstation'
import operations from './operations/operation_index'
import auth from './auth/auth'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loader,
    auth,
    typeIndex,
    operations,
    machineCategories,
    stopCategories,
    categories,
    machines,
    workstations,
  },
  strict: process.env.DEV,
})
