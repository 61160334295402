<template>
  <b-card
    class="p-0 pt-1 "
    :class="{'no-border': !showBorder }"
  >
    <b-card-body
      class="p-0"
    >
      <b-row
        class="align-items-center mb-1"
      >
        <b-col
          v-if="showSearch"
          class="text-right"
          md="9"
          sm="9"
          xs="9"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Digite aqui para pesquisar"
            />
            <b-input-group-append>
              <b-button
                size="sm"
                @click="onClear"
              >
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          v-if="!showSearch"
          md="12"
        >
          <slot name="search" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="9"/>
        <b-col
          :md="3"
          :sm="3"
          :xs="3"
          class="align-items-end"
          style="text-align: right"
        >{{ totalRows }} Registros</b-col>
      </b-row>
      <b-table
        id="i2GridTable"
        ref="i2GridTable"
        striped
        hover
        sticky-header
        bordered
        show-empty
        responsive
        :small="true"
        :no-border-collapse="true"
        :per-page="0"
        :current-page="currentPage"
        :items="itemList"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        :no-local-sorting="isServerData"
        @filtered="onFiltered"
        @sort-changed="sortingChanged"
      >
        <template #empty>
          <b-row class="text-center">
            <b-col>
              <b-img :src="require('@/assets/images/icons/empty.svg')" height="30" />
              Nenhum registro encontrado
            </b-col>
          </b-row>
        </template>
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="data"
        >
          <slot
            :name="slotName"
            v-bind="data"
          />
        </template>
      </b-table>

      <b-row v-if="showPagination" class="pl-1 pr-1 pt-0">
        <b-col
          md="6"
          sm="6"
          xs="6"
        >
          <b-form-group
            class="mb-0"
            style="text-align: left"
          >
            <label class="d-inline-block text-sm-left pr-1">Por página: </label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              class="w-25"
              @change="onPerPageChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          sm="6"
          xs="6"
          style="text-align: right"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            @change="handlePageChange"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Grid',
  components: {
    BTable,
    BCard,
    BPagination,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
  },
  props: {
    fields: {
      type: [Array],
      required: true,
    },
    items: {
      type: [Array, Object],
      required: true,
    },
    newButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: false,
    },
    editButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
      required: false,
    },
    showBorder: {

      required: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: () => ({
    perPage: 25,
    pageOptions: [10, 25, 50, 75, 100],
    totalRows: 0,
    currentPage: 1,
    filter: null,
    itemList: [],
    breakPoint: '',
    filterOn: [],
    isServerData: false,
  }),
  computed: {
    ...mapState(['app/windowWidth']),
  },
  watch: {
    items(data) {
      this.itemList = data.data || data
      let totalRows = this.itemList.length || 0
      if (data.meta) {
        this.isServerData = true
        totalRows = data.meta.total
        this.itemList = data.data
        this.currentPage = data.meta.current_page
      }
      this.$refs.i2GridTable.refresh()
      this.totalRows = totalRows
      setTimeout(() => {
        this.$refs.i2GridTable.refresh()
        this.$forceUpdate()
      }, 300)
      this.breakPoint = this.currentBreakPoint()
    },
    itemList() {
      this.$refs.i2GridTable.refresh()
      this.$root.$emit('bv::refresh::table', 'i2GridTable')
    },
  },
  created() {
    this.breakPoint = this.currentBreakPoint()
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'app/UPDATE_WINDOW_WIDTH') {
        this.breakPoint = this.currentBreakPoint()
      }
    })
    let totalRows = this.items.length
    this.itemList = this.items.data || this.items
    if (this.items.meta) {
      totalRows = this.items.meta.total
      this.currentPage = 1
    }
    this.totalRows = totalRows
  },
  updated() {

  },
  methods: {
    ...mapGetters('app', ['currentBreakPoint']),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange(page) {
      const event = {
        page,
        per_page: this.perPage,
      }
      this.$emit('onChangePage', event)
    },
    onPerPageChange() {
      const event = {
        page: this.currentPage,
        per_page: this.perPage,
      }
      this.$emit('onChangePage', event)
    },
    onClear() {
      this.filter = ''
      this.$emit('onClear')
    },
    sortingChanged(ctx) {
      const event = {
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: ctx.sortBy,
        sort_order: ctx.sortDesc ? 'desc' : 'asc',
      }
      this.$emit('onChangePage', event)
    },
  },
}

</script>
<style>
th.w-5 {
  width: 5%;
}

th.w-10 {
  width: 10%;
  text-wrap: none;
}
th.w-15 {
  width: 15%;
  text-wrap: none;
}
th.w-20 {
  width: 20%;
}
th.w-25 {
  width: 25%;
}
th.w-30 {
  width: 30%;
}
th.w-35 {
  width: 35%;
}
th.w-40 {
  width: 40%;
}
th.w-45 {
  width: 45%;
}
th.w-50 {
  width: 50%;
}
th.w-55 {
  width: 55%;
}
th.w-60 {
  width: 60%;
}
th.w-65 {
  width: 65%;
}
th.w-70 {
  width: 70%;
}
th.w-75 {
  width: 75%;
}
th.w-80 {
  width: 80%;
}
th.w-85 {
  width: 85%;
}
th.w-90 {
  width: 90%;
}
th.w-95 {
  width: 95%;
}
.no-border {
  border:0px! important
}
.table thead th, .table tfoot th {
  vertical-align: top;
  text-align: center !important;
  text-transform:capitalize !important;
}
.table tr {
  cursor: default !important;
}
table td.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
td.action  {
  white-space: nowrap;
}
</style>
