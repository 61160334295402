const state = {
  machines: null,
  current_machine: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_CURRENT_MACHINE(state, payload) {
    state.current_machine = payload
  },
}

const actions = {
  getByUuid: async ({ commit }, uuid) => {
    try {
      const data = await window.http.get(`machines/${uuid}`)
      commit('SET_CURRENT_MACHINE', data)
      return data
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
