<template>
  <b-form-group
    :label="label"
    :label-for="name"
    class="mb-1"
  >
    <validation-provider
      #default="{ errors }"
      :name="label"
      :vid="label"
      :rules="rules"
    >

      <v-select
        v-model="inputValue"
        :name="name"
        multiple
        :options="options"
        label="text"
        :readonly="readonly"
        :disabled="disabled"
        @change="onChange"
      >
        <div slot="no-options">
          Nenhum(a) {{ label }} encontrado(a).
        </div>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>

</template>

<script>

import { ValidationProvider } from 'vee-validate'

export default {
  name: 'I2FormMultipleSelect',
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    rules: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      selected: null,
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    this.inputValue = this.value
  },
  methods: {
    onChange(data) {
      this.$emit('onChange', data)
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
