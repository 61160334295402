const operations = [
  {
    path: '/operations',
    name: 'operation.list',
    component: () => import('@/views/operations/OperationList.vue'),
  },
  {
    path: '/appointments/:workstation_uuid',
    name: 'appointment.show',
    component: () => import('@/views/appointments/Appointment.vue'),
  },
]

export default operations
