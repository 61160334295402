<template>
  <b-col
    v-bind="$attrs"
  >
    <slot />
  </b-col>
</template>

<script>
export default {
  name: 'SCol',
}
</script>

<style scoped>

</style>
