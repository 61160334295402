const state = {
  operations: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_OPERATIONS(state, payload) {
    state.operations = payload
  },
}

const actions = {
  operations: async ({ commit }) => {
    try {
      const data = await window.http.get('operations')
      commit('SET_OPERATIONS', data)
      return data
    } catch (error) {
      return error
    }
  },
  getOperations: async ({ commit }) => {
    try {
      const data = await window.http.get('operations')
      commit('SET_OPERATIONS', data)
      return data
    } catch (error) {
      return error
    }
  },
  setIndexes: async ({ commit }, index) => {
    commit('SET_OPERATIONS', index)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
